@tailwind components;
@tailwind utilities;

html,
body {
  min-height: 100%;
  font-family: 'Poppins';
  margin: 0;
}

#__next {
  height: 100%;
}

code[class*='language-'] {
  padding: 0;
}

.MuiButtonBase-root.MuiButton-root,
.button {
  font-family: 'Poppins';
  padding: 8px 32px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
}

.MuiButtonBase-root.MuiButton-root.small {
  padding: 4px 16px;
  border-radius: 20px;
}

button[type='submit'] {
  background: linear-gradient(92.09deg, #de53f7 1.76%, #7549f2 98.87%);
}

@layer components {
  /* TEXT */
  h1,
  h1.MuiTypography-root.MuiTypography-h1 {
    @apply font-bold text-7xl text-black;
    font-family: 'Poppins';
  }
  h2,
  h2.MuiTypography-root.MuiTypography-h2 {
    @apply font-bold text-5xl text-black;
    font-family: 'Poppins';
  }
  h3,
  h3.MuiTypography-root.MuiTypography-h3 {
    @apply font-bold text-4xl text-black;
    font-family: 'Poppins';
  }
  h4,
  h4.MuiTypography-root.MuiTypography-h4 {
    @apply font-bold text-3xl text-black;
    font-family: 'Poppins';
  }
  h5,
  h5.MuiTypography-root.MuiTypography-h5 {
    @apply font-bold text-2xl text-black my-4;
    font-family: 'Poppins';
  }
  h6,
  h6.MuiTypography-root.MuiTypography-h6 {
    @apply font-bold text-xl text-black;
    font-family: 'Poppins';
  }
  p,
  p.MuiTypography-root.MuiTypography-body1 {
    @apply text-base text-black;
    font-family: 'Poppins';
  }
  .body2,
  p.MuiTypography-root.MuiTypography-body2 {
    @apply text-sm text-black;
    font-family: 'Poppins';
  }
  .subtitle1,
  h6.MuiTypography-root.MuiTypography-subtitle1 {
    @apply text-sm text-text-body font-bold;
    font-family: 'Poppins';
  }
  .subtitle2,
  h6.MuiTypography-root.MuiTypography-subtitle2 {
    @apply text-xs text-text-header;
    font-family: 'Poppins';
  }
  .text-gradient {
    background-color: rgba(117, 73, 242, 0.87);
    background: linear-gradient(90.05deg, rgba(117, 73, 242, 0.87) 1.25%, rgba(222, 83, 247, 0.87) 48.15%);
    background-clip: text;
    text-fill-color: transparent;
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
  .text-gradient-light {
    background-color: rgba(222, 83, 247, 0.87);
    background: linear-gradient(5deg, rgba(117, 73, 242, 0.87) 1.25%, rgba(222, 83, 247, 0.87) 48.15%);
    background-clip: text;
    text-fill-color: transparent;
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }

  .MuiDrawer-paper {
    margin: 0 auto;
  }

  .MuiStepLabel-label.Mui-active {
    @apply text-gradient;
  }
  .MuiStepLabel-label.Mui-active svg.MuiSvgIcon-root {
    color: rgba(222, 83, 247, 0.87);
  }

  .menuText {
    color: rgba(222, 83, 247, 0.87);
  }

  /* BUTTON */
  .previous {
    background: #ededed !important;
    color: #000 !important;
    border-radius: 20px;
    @apply text-black;
  }

  .bg-gradient-dark {
    background: linear-gradient(92.09deg, #ef8b55 42.76%, #de53f7 98.87%);
  }

  .primary-button {
    background: linear-gradient(92.09deg, #de53f7 1.76%, #7549f2 98.87%);
    border: 0;
    border-radius: 20px;
  }

  .gradient-line {
    background: linear-gradient(92.09deg, #de53f7 1.76%, #7549f2 98.87%);
    border: 0;
    border-radius: 20px;
  }

  .text-button {
    @apply text-xl text-gradient my-4;
    /* color: linear-gradient(92.09deg, #de53f7 1.76%, #7549f2 98.87%); */
    font-family: 'Poppins';
    border: 0;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: rgba(117, 73, 242, 0.87);
    text-decoration-line: underline;
    text-decoration-thickness: 1;
  }

  .link {
    @apply font-bold text-gradient;
    font-family: 'Poppins';
    cursor: pointer;
  }

  .bottom-buttons {
    @apply flex flex-row justify-between md:justify-center md:gap-8 flex-1 mb-4;
  }

  /* BORDER */
  div.MuiCard-root.border-gradient {
    background:
      linear-gradient(white, white) padding-box,
      linear-gradient(to bottom, #7549f2, #de53f7) border-box;
    border-radius: 6px;
    border: 3px solid transparent;
  }

  .status-table td {
    @apply w-fit;
    text-align: left;
    vertical-align: top;
  }
  .status-table .heading {
    @apply font-bold px-2;
    text-align: right;
    white-space: nowrap;
  }
  .status-table {
    @apply w-fit;
    justify-self: center;
  }
  .status-table * {
    @apply w-fit;
  }
}
/* border: 3px solid; */
/* linear-gradient(180deg, #7549F2 0%, #DE53F7 100%); */
/* border-image-source:  */
